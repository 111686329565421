@charset "utf-8";
@import "base";
/* CSS Document */
/*====================================================================

	helper.css

=====================================================================*/

/* display */
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .d#{$infix}-none {
      display: none !important;
    }
    .d#{$infix}-inline {
      display: inline !important;
    }
    .d#{$infix}-inline-block {
      display: inline-block !important;
    }
    .d#{$infix}-block {
      display: block !important;
    }
    .d#{$infix}-table {
      display: table !important;
    }
    .d#{$infix}-table-row {
      display: table-row !important;
    }
    .d#{$infix}-table-cell {
      display: table-cell !important;
    }
    .d#{$infix}-flex {
      display: flex !important;
    }
    .d#{$infix}-inline-flex {
      display: inline-flex !important;
    }
  }
  @include media-breakpoint-down($breakpoint) {
    $infix: breakpoint-less($breakpoint, $grid-breakpoints);
    .d#{$infix}-less-none {
      display: none !important;
    }
    .d#{$infix}-less-inline {
      display: inline !important;
    }
    .d#{$infix}-less-inline-block {
      display: inline-block !important;
    }
    .d#{$infix}-less-block {
      display: block !important;
    }
    .d#{$infix}-less-table {
      display: table !important;
    }
    .d#{$infix}-less-table-row {
      display: table-row !important;
    }
    .d#{$infix}-less-table-cell {
      display: table-cell !important;
    }
    .d#{$infix}-less-flex {
      display: flex !important;
    }
    .d#{$infix}-less-inline-flex {
      display: inline-flex !important;
    }
  }
}

/* img */
.light-box {
  @include light-box;
}
.img-scale {
  @include img-scale;
}

//アスペクト比固定用
.img-aspect {
  @include aspect-img;
}

/* ofi */
.ofi {
  &-contain {
    @include ofi-contain;
  }
  &-cover {
    @include ofi-cover;
  }
}

.of-hidden {
  overflow: hidden;
}

/* color */
.cl {
  &--red {
    color: $red;
  }
  &--blue {
    color: $blue;
  }
}

.required {
  display: inline-block;
  padding:0 .3em;
  margin-right: .3em;
  color:$white;
  background: $red;
  @include f-em(14);
}

/* font */
//font size
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .fs#{$infix}-s {
      font-size: 0.875em;
    }
    .fs#{$infix}-m {
      font-size: 1.14em;
    }
    .fs#{$infix}-l {
      font-size: 1.25em;
    }
    .fs#{$infix}-xl {
      font-size: 1.5em;
    }
  }
  @include media-breakpoint-down($breakpoint) {
    $infix: breakpoint-less($breakpoint, $grid-breakpoints);
    .fs#{$infix}-less-s {
      font-size: 0.875em;
    }
    .fs#{$infix}-less-m {
      font-size: 1.14em;
    }
    .fs#{$infix}-less-l {
      font-size: 1.25em;
    }
    .fs#{$infix}-less-xl {
      font-size: 1.5em;
    }
  }
}
.fs-14 {
  @include f-size(14);
}

.fw- {
  &400 {
    @include f-w(400);
  }
  &500 {
    @include f-w(500);
  }
  &600 {
    @include f-w(600);
  }
  &700 {
    @include f-w(700);
  }
}

.f- {
  &font01 {
    @include f-family(font01);
  }
  &font02 {
    @include f-family(font02);
  }
  &font03 {
    @include f-family(font03);
  }
}

/* txt-align */
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .text#{$infix}-left {
      text-align: left !important;
    }
    .text#{$infix}-right {
      text-align: right !important;
    }
    .text#{$infix}-center {
      text-align: center !important;
    }
  }
  @include media-breakpoint-down($breakpoint) {
    $infix: breakpoint-less($breakpoint, $grid-breakpoints);
    .text {
      .text#{$infix}-less-left {
        text-align: left !important;
      }
      .text#{$infix}-less-right {
        text-align: right !important;
      }
      .text#{$infix}-less-center {
        text-align: center !important;
      }
    }
  }
}

/* vertical-align */
.align-baseline    { vertical-align: baseline !important; } // Browser default
.align-top         { vertical-align: top !important; }
.align-middle      { vertical-align: middle !important; }
.align-bottom      { vertical-align: bottom !important; }
.align-text-bottom { vertical-align: text-bottom !important; }
.align-text-top    { vertical-align: text-top !important; }

/* clearfix */
.clearfix {
  @include clearfix;
}

/* botton */

/* default
btn_normal(テキストカラー:$white,バックグランドカラー:$main_c,ボーダーカラー:$main_c,$テキストホバーカラー:$white,バックグランドホバーカラー:$point_c,ボーダーホバーカラー:$point_c)
btn_arrow(テキストカラー:$white,バックグランドカラー:$main_c,ボーダーカラー:$main_c,$テキストホバーカラー:$white,バックグランドホバーカラー:$point_c,ボーダーホバーカラー:$point_c)
btn_line(テキストカラー:$main_c,バックグランドカラー:$white,ボーダーカラー:$main_c,$テキストホバーカラー:$white,バックグランドホバーカラー:$point_c,ボーダーホバーカラー:$black)
*/

.common-btn {
  &--main {
    @include btn_line();
  }
  &--black {
    @include btn_line($black,$white,$black,$white,$black,$d-gray);
  }
}


/* margin */
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {
        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length;
        }
        .#{$abbrev}t#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-top: $length;
        }
        .#{$abbrev}r#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-right: $length;
        }
        .#{$abbrev}b#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-bottom: $length;
        }
        .#{$abbrev}l#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-left: $length;
        }
      }
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .m-a {
      &#{$infix} {
        @include m-a;
      }
    }
  }
  @include media-breakpoint-down($breakpoint) {
    $infix: breakpoint-less($breakpoint, $grid-breakpoints);
    .m-a {
      &#{$infix}-less {
        @include m-a;
      }
    }
  }
}

/* max-width */
.w- {
  &280 {
    max-width: 280px;
  }
  &360 {
    max-width: 360px;
  }
  &800 {
    max-width: 800px;
  }
  &960 {
    max-width: 960px;
  }
  &1200 {
    max-width: 1200px;
  }
  &100p {
    width: 100%;
  }
}
/* height */
.h- {
  &100 {
    height: 100%;
  }
}

/* scroll-bar */
.scroll-bar {
	@include scroll-bar;
}

/* flexbox */
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .flex#{$infix}-row {
      flex-direction: row !important;
    }
    .flex#{$infix}-column {
      flex-direction: column !important;
    }
    .flex#{$infix}-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex#{$infix}-column-reverse {
      flex-direction: column-reverse !important;
    }

    .flex#{$infix}-wrap {
      flex-wrap: wrap !important;
    }
    .flex#{$infix}-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex#{$infix}-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .flex#{$infix}-fill {
      flex: 1 1 auto !important;
    }
    .flex#{$infix}-grow-0 {
      flex-grow: 0 !important;
    }
    .flex#{$infix}-grow-1 {
      flex-grow: 1 !important;
    }
    .flex#{$infix}-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex#{$infix}-shrink-1 {
      flex-shrink: 1 !important;
    }
    .justify-content#{$infix}-start {
      justify-content: flex-start !important;
    }
    .justify-content#{$infix}-end {
      justify-content: flex-end !important;
    }
    .justify-content#{$infix}-center {
      justify-content: center !important;
    }
    .justify-content#{$infix}-between {
      justify-content: space-between !important;
    }
    .justify-content#{$infix}-around {
      justify-content: space-around !important;
    }

    .align-items#{$infix}-start {
      align-items: flex-start !important;
    }
    .align-items#{$infix}-end {
      align-items: flex-end !important;
    }
    .align-items#{$infix}-center {
      align-items: center !important;
    }
    .align-items#{$infix}-baseline {
      align-items: baseline !important;
    }
    .align-items#{$infix}-stretch {
      align-items: stretch !important;
    }

    .align-content#{$infix}-start {
      align-content: flex-start !important;
    }
    .align-content#{$infix}-end {
      align-content: flex-end !important;
    }
    .align-content#{$infix}-center {
      align-content: center !important;
    }
    .align-content#{$infix}-between {
      align-content: space-between !important;
    }
    .align-content#{$infix}-around {
      align-content: space-around !important;
    }
    .align-content#{$infix}-stretch {
      align-content: stretch !important;
    }

    .align-self#{$infix}-auto {
      align-self: auto !important;
    }
    .align-self#{$infix}-start {
      align-self: flex-start !important;
    }
    .align-self#{$infix}-end {
      align-self: flex-end !important;
    }
    .align-self#{$infix}-center {
      align-self: center !important;
    }
    .align-self#{$infix}-baseline {
      align-self: baseline !important;
    }
    .align-self#{$infix}-stretch {
      align-self: stretch !important;
    }
  }
}

/* Border */
.border {
  border: $border-width solid $border-color !important;
}
.border-top {
  border-top: $border-width solid $border-color !important;
}
.border-right {
  border-right: $border-width solid $border-color !important;
}
.border-bottom {
  border-bottom: $border-width solid $border-color !important;
}
.border-left {
  border-left: $border-width solid $border-color !important;
}

.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
@each $color, $value in $theme-colors {
  .border-#{$color} {
    border-color: $value !important;
  }
}

// Border-radius
.rounded {
  border-radius: $border-radius !important;
}
.rounded-top {
  border-top-left-radius: $border-radius !important;
  border-top-right-radius: $border-radius !important;
}
.rounded-right {
  border-top-right-radius: $border-radius !important;
  border-bottom-right-radius: $border-radius !important;
}
.rounded-bottom {
  border-bottom-right-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}
.rounded-left {
  border-top-left-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-0 {
  border-radius: 0 !important;
}

// glid
@include make-grid-columns();
.container {
  @include make-container();
  @include make-container-max-widths();
}
.container-fluid {
  @include make-container();
}
.row {
  @include make-row();
  > [class*="col-"] {
    img {
      width: 100%;
    }
  }
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}
