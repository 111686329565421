@mixin btn_base {
	font-weight: normal;
	display: block;
	text-align: center;
	text-decoration: none;
	position: relative;
	@include f-family(font01);
	line-height: 1.4;
	@include transition;
	@include dec-none;
}

// btn_normal
@mixin btn_normal($color:$white,$bg:$main_c,$border:$main_c,$color_h:$white,$bg_h:$point_c,$border_h:$point_c){
	@include btn_base;
	color:$color;
	background-color: $bg;
	border-color: $border;
	padding:1.5rem 4%;
	width: 100%;
	@include f-w(500);
	&:hover{
		color: $color_h;
		background-color:$bg_h;
		border-color:$border_h;
	}
}

// btn_arrow
@mixin btn_arrow($color:$main_c,$bg:$white,$border:$main_c,$color_h:$white,$bg_h:$point_c,$border_h:$point_c){
	@include btn_base;
	color:$color;
	background-color: $bg;
	border:1px solid;
	border-color: $border;
	padding:1.5rem 4%;
	width: 100%;
	@include icon(right_arrow,after);
	&::after {
		position:absolute;
		right:1rem;
		top:50%;
		transform: translateY(-50%);
		@include transition;
	}
	&:hover{
		color: $color_h;
		background-color:$bg_h;
		border-color:$border_h;
	}
}

// btn_line
@mixin btn_line($color:$main_c,$bg:$white,$border:$main_c,$color_h:$white,$bg_h:$point_c,$border_h:$black){
	@include btn_base;
	color:$color;
	background-color: $bg;
	border:1px solid;
	border-color: $border;
	padding:1.5rem 4%;
	width: 100%;
	@include f-w(500);
	&:hover{
		color: $color_h;
		background-color:$bg_h;
		border-color:$border_h;
		&::after {
			width: 4em;
			background-color:$border_h;
		}
	}
	&::after {
		position: absolute;
		top:50%;
		left: calc(100% - 1.5em);
		width: 3em;
		height: 1px;
		content: "";
		@include transition;
		background: $border;
	}
}
